<template>
  <div
    class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
  >
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <div
        class="col-12 xl:col-6"
        style="
          border-radius: 56px;
          padding: 0.3rem;
          background: linear-gradient(
            180deg,
            var(--primary-color),
            rgba(33, 150, 243, 0) 30%
          );
        "
      >
        <div
          class="h-full w-full m-0 py-7 px-4"
          style="
            border-radius: 53px;
            background: linear-gradient(
              180deg,
              var(--surface-50) 38.9%,
              var(--surface-0)
            );
          "
        >
          <div class="text-center mb-5">
            <div class="text-900 text-3xl font-medium mb-3">
              Welcome to FNT!
            </div>
            <span class="text-600 font-medium">Sign in to continue</span>
          </div>

          <div class="w-full md:w-10 mx-auto">
            <label
              for="email"
              class="block text-900 text-xl font-medium mb-2"
              >{{ $t("auth.username") }}</label
            >
            <InputText
              id="email"
              v-model="user.username"
              type="text"
              class="w-full mb-3"
              :placeholder="$t('auth.username')"
              style="padding: 1rem"
            />

            <label
              for="password"
              class="block text-900 font-medium text-xl mb-2"
              >{{ $t("auth.password") }}</label
            >
            <Password
              id="password"
              v-model="user.password"
              :placeholder="$t('auth.password')"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              :inputStyle="{ padding: '1rem' }"
            ></Password>

            <div class="flex align-items-center justify-content-between mb-5">
              <div class="flex align-items-center">
                <Checkbox
                  id="rememberme"
                  v-model="keepLoggedIn"
                  :binary="true"
                  class="mr-2"
                ></Checkbox>
                <label for="rememberme">{{ $t("auth.remember_me") }}</label>
              </div>
              <a
                class="font-medium no-underline ml-2 text-right cursor-pointer"
                style="color: var(--primary-color)"
                >{{ $t("auth.reset_password") }}</a
              >
            </div>
            <Button
              :label="$t('button.signin')"
              class="w-full p-3 text-xl"
              @click="onSubmit"
              @touchstart="onSubmit"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { mapActions } from 'vuex'
import { getDefaultRoute } from "@/router";
import AuthService from "@/service/AuthService";
export default {
  name: "login",
  data() {
    return {
      user: {
        username: null,
        password: null,
      },
      submitted: false,
      keepLoggedIn: false,
      usernameErrors: [],
      passwordErrors: [],
    };
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
    formReady() {
      return !this.usernameErrors.length && !this.passwordErrors.length;
    },
  },
  methods: {
    /*
      ...mapActions('auth', [
        'login', // map `this.login()` to `this.$store.commit('login')`
      ]),
      */
    onSubmit() {
      //const userAgent = window.navigator.userAgent;
      //console.log(userAgent);
      this.usernameErrors = this.user.username ? [] : ["Username is required"];
      this.passwordErrors = this.user.password ? [] : ["Password is required"];
      //Use this for mobile
      if (
        this.usernameErrors.length == 0 &&
        this.passwordErrors.length == 0 &&
        !this.submitted
      ) {
        this.summitted = true;
        AuthService.login(this.user).then((user) => {
          const defRoute = getDefaultRoute(user);
          this.$router.push({ name: defRoute });
        });
        /*
          this.login(this.user).then(() => {
            this.summitted = false;
            if (this.returnUrl && AuthService.authenticate(this.returnUrl)) {
              this.$router.push({name:this.returnUrl})
            } else {
              const route = {name:getDefaultRoute(user)};
              this.$router.push(route)
            }
          })
          */
      }
    },
  },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
